import { configureStore } from "@reduxjs/toolkit";
import cartFieldsReducer from "../features/cartFields";
import cartProgressBarReducer from "../features/cartProgressBarSlice";
import cartReducer from "../features/cartSlice";
import dataReducer from "../features/dataSlice";
import scrollReducer from "../features/scrollSlice";
import languageReducer from "../features/languageSlice";
const store = configureStore({
  reducer: {
    cart: cartReducer,
    scroll: scrollReducer,
    cartProgressBar: cartProgressBarReducer,
    cartFields: cartFieldsReducer,
    data: dataReducer,
    language: languageReducer,
  },
});

export default store;
