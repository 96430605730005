import React from "react";
import Product from "./HomeProduct";
import products from "../../../../data";
import { translate } from "../../../../i18n";

function Products() {
  // const [filter, setFilter] = useState("all");
  // console.log(filter);

  return (
    <section className="products">
      <div className="container">
        <h3 className="products_title">{translate("productsTitleHome")}</h3>
        {/* <div className="products_filters">
          <button
            onClick={() => {
              setFilter("all");
            }}
          >
            Toate produsele
          </button>
          <button
            onClick={() => {
              setFilter("filler");
            }}
          >
            Așternut igienic
          </button>
          <button
            onClick={() => {
              setFilter("food");
            }}
          >
            Mâncare
          </button>
        </div> */}
        <ul className="products_list">
          {products.filter((product) => product.type !== "hidden").map((product) => (
                <Product key={product._id} product={product} /> // să las așa sau să transmit doar id-ul
              ))}
        </ul>
      </div>
    </section>
  );
}

export default Products;
