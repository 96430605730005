import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faHandHoldingDollar, faReceipt } from "@fortawesome/free-solid-svg-icons";
import { translate } from "../../i18n";

function Footer() {
  // const telephonenr = "079079461";
  // const telephonenr = "078101064";
  const telephonenr = "076607777";
  return (
    <div className="footer_content">
      <div className="container">
        <section className="footer_contact">
          <h6>{translate("contact")}:</h6>
          <article className="link">
            <a href={`tel:${telephonenr}`}>
              <FontAwesomeIcon className="infobar_faicon" icon={faPhone} />{" "}
              <span>{telephonenr}</span>
            </a>
          </article>
          <article className="">
            <FontAwesomeIcon className="infobar_faicon" icon={faEnvelope} />{" "}
            <span>kiwishopmd@gmail.com</span>
          </article>
        </section>
        <section className="footer_delivery">
          <h6>{translate("delivery")}:</h6>
          {/* <article className="">
            <FontAwesomeIcon className="infobar_faicon" icon={faReceipt} />{" "}
            <span>{translate("delivery1")}</span>
          </article> */}
          <article className="">
            <FontAwesomeIcon className="infobar_faicon" icon={faHandHoldingDollar} />{" "}
            <span>{translate("delivery2")}</span>
          </article>
        </section>
      </div>
      {/* <div className="App_footer_cats" style={{backgroundImage: 'url(' + import('./footercats1.png') + ')'}}></div> */}
    </div>
  );
}

export default Footer;
