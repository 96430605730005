import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import ProductQuantity from "../../General/ProductQuantity/ProductQuantity";
import { useNavigate, useParams } from "react-router-dom";
import products, { productsRussian } from "../../../data";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../features/cartSlice.js";
import { LOCALES, translate } from "../../../i18n";
import { useSelector } from "react-redux";
import { setMaxStep } from "../../../features/cartProgressBarSlice";

function ProductScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.cartProgressBar.currentStep);

  const product = products.find((product) => product._id === id);
  const navigate = useNavigate();

  const [quantity, setQuantity] = useState(1);
  const [savedQuantity, setSavedQuantity] = useState(1);
  const [productAddedToCart, setProductAddedToCart] = useState(false);

  const handleAddToCart = () => {
    setSavedQuantity(quantity);
    setProductAddedToCart(true);
    dispatch(addToCart({ id: product._id, quantity: quantity }));

    setTimeout(() => {
      setProductAddedToCart(false);
    }, 4500);
  };

  const [productLocal, setProductLocal] = useState(product);
  const language = useSelector((state) => state.language);
  useEffect(() => {
    if (product.type === "hidden") {
      navigate("/");
    }
    if (currentStep === 5) {
      dispatch(setMaxStep(1));
      window.location.reload(false);
    }
    const productRu = productsRussian.find((prod) => prod._id === product._id);

    if (language === LOCALES.RUSSIAN) {
      setProductLocal({
        ...product,
        name: productRu.name,
        description: productRu.description,
      });
    } else {
      setProductLocal(product);
    }
  }, [language]);

  if (product.type === "hidden") {
    return null;
  }

  return (
    <section className="productScreen">
      <div className="container">
        <div className="productScreen_content">
          <article className="productScreen_image">
            <img src={product.image2} alt={productLocal.name} />
          </article>
          <section className="productScreen_info">
            <div className="flex-40-grow">
              <h2 className="productScreen_name">{productLocal.name}</h2>
              <h3 className="productScreen_description">
                {productLocal.description}
              </h3>
            </div>

            <p className="productScreen_price">
              {/* {product.price} <span>{translate("lei")}</span> */}
              {product.price}{" "}
              <span className="price_currency">{translate("lei")}</span> (
              {product.priceUnit}{" "}
              <span className="price_currency">{translate("lei")}</span> x{" "}
              {product.complect} {translate("unit")})
            </p>
            <ProductQuantity quantity={quantity} setQuantity={setQuantity} />
            <div className="productScreen_addToCartButton">
              {productAddedToCart && (
                <p style={{ color: "#0028b9", paddingBottom: "5px" }}>
                  {translate("theProduct")} «{productLocal.name}» x{" "}
                  {savedQuantity} {translate("wasAddedToCart")}
                </p>
              )}
              <button
                
                // disabled={productAddedToCart || currentStep === 5}
                // className={
                //   productAddedToCart || currentStep === 5
                //     ? "button--disabled"
                //     : ""
                // }
                className={"button--disabled"}
                
            disabled
                onClick={handleAddToCart}
                type="button"
              >
                <span>{translate("addToCartButton")}</span>
                <FontAwesomeIcon icon={faCartPlus} />
              </button>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
}

export default ProductScreen;
