import React, { useState } from "react";
import { useSelector } from "react-redux";
import CartProducts from "./Products/CartProducts";
import PersonalData from "./PersonalData";
import ProgressBar from "./ProgressBar";
import Summary from "./Summary";
import Address from "./Address";
import Payment from "./Payment";
import OrderPlaced from "./OrderPlaced";
import { translate } from "../../../i18n";

function CartScreen() {
  const currentStep = useSelector((state) => state.cartProgressBar.currentStep);

  return (
    <section className="cart">
      <div className="container">
        <section className="cart_header">
          {currentStep < 5 ? <h6 className="cart_title">{translate("cart")}</h6> : ""}

          {currentStep < 5 ? <ProgressBar /> : ""}
        </section>
        <section className="cart_flex">
          {currentStep === 1 ? (
            <CartProducts  key={Date.now()}/>
          ) : currentStep === 2 ? (
            <PersonalData />
          ) : currentStep === 3 ? (
            <Address />
          ) : currentStep === 4 ? (
            <Payment />
          ) : (
            <OrderPlaced />
          )}
          {currentStep < 5 ? <Summary /> : ""}
        </section>
      </div>
    </section>
  );
}

export default CartScreen;
