import React from "react";
import { useLocation } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import HeaderContent from "./components/Header/HeaderContent";
import ScrollToTop from "./helpers/components/ScrollToTop";
import Banner from "./components/General/Banner/Banner";

function Layout({ children }) {
  const location = useLocation();
  const isAdminRoute =
    location.pathname === "/aS4damN4id" ||
    "/aS4damN4id/orders" ||
    "/aS4damN4id/products" ||
    "/aS4damN4id/promocodes" ||
    "/aS4damN4id/statistics";

  return (
    <div className="Layout">
      <header className="Layout_header">
        <HeaderContent />
      </header>
      <main className="Layout_main">
        <Banner />
        <ScrollToTop />
        {children}
      </main>
      <footer className="Layout_footer">
        <Footer />
      </footer>
    </div>
  );
}

export default Layout;
