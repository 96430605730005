import React, { useEffect, useState } from "react";
import InfoBar from "./InfoBar";
import NavBar from "./NavBar";

function HeaderContent() {
  const [infoBarHidden, setInfoBarHidden] = useState(false);
  let lastScrollY = window.scrollY;

  const controlInfobar = () => {
    if (lastScrollY < window.scrollY) {
      if (window.scrollY > 55) {
        setInfoBarHidden(true);
      }
    } else {
      setInfoBarHidden(false);
    }

    lastScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener("scroll", controlInfobar);
    return () => {
      window.removeEventListener("scroll", controlInfobar);
    };
  }, []);
  return (
    <>
      <InfoBar infoBarHidden={infoBarHidden} />
      <NavBar infoBarHidden={infoBarHidden} />
    </>
  );
}

export default HeaderContent;
