import React, { useState } from "react";
import "./ProductQuantity.scss";

function ProductQuantity(props) {
  const { quantity, setQuantity } = props;

  function quantityCheck(quantity) {
    quantity < 1
      ? setQuantity(1)
      : quantity > 999
      ? setQuantity(999)
      : setQuantity(quantity);
  }

  return (
    <article className="productQuantity">
      <button
        type="button"
        className="productQuantity_buttons_subtract"
        onClick={() => quantityCheck(quantity - 1)}
      >
        -
      </button>
      <div className="productQuantity_display">{quantity}</div>
      <button
        type="button"
        className="productQuantity_buttons_add"
        onClick={() => quantityCheck(quantity + 1)}
      >
        +
      </button>
    </article>
  );
}

export default ProductQuantity;
