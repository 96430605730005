import React from "react";
import Buttons from "./NavbarComponents/Buttons";
import Utilities from "./NavbarComponents/Utilities";

function NavBar(props) {
  const { infoBarHidden } = props;
  
  return (
    <nav className={infoBarHidden ? "navbar infobar--hidden" : "navbar"}>
      <div className="container">
        <main className="navbar_content">
          <Buttons />
          <Utilities />
        </main>
      </div>
    </nav>
  );
}

export default NavBar;
