import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { goToStep } from "../../../features/cartProgressBarSlice";
import { translate } from "../../../i18n";
import {
  faShoppingCart,
  faUser,
  faLocationDot,
  faDollar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ProgressBar() {
  const currentStep = useSelector((state) => state.cartProgressBar.currentStep);
  const maxStep = useSelector((state) => state.cartProgressBar.maxStep);

  const dispatch = useDispatch();

  return (
    <div className="cart_progress">
      <article
        onClick={() => {
          dispatch(goToStep(1));
        }}
        className="cart_progress_step active"
      >
        <span>
          <FontAwesomeIcon icon={faShoppingCart} />
        </span>
        <span className="bigScreen">{translate("cart")}</span>
      </article>
      <article
        onClick={() => {
          dispatch(goToStep(2));
        }}
        className={
          currentStep >= 2
            ? "cart_progress_step active"
            : maxStep >= 2
            ? "cart_progress_step"
            : "cart_progress_step disabled"
        }
      >
        <span>
          <FontAwesomeIcon icon={faUser} />
        </span>
        <span className="bigScreen">{translate("personalData")}</span>
      </article>
      <article
        onClick={() => {
          dispatch(goToStep(3));
        }}
        className={
          currentStep >= 3
            ? "cart_progress_step active"
            : maxStep >= 3
            ? "cart_progress_step"
            : "cart_progress_step disabled"
        }
      >
        <span>
          <FontAwesomeIcon icon={faLocationDot} />
        </span>
        <span className="bigScreen">{translate("address")}</span>
      </article>
      <article
        onClick={() => {
          dispatch(goToStep(4));
        }}
        className={
          currentStep >= 4
            ? "cart_progress_step active"
            : maxStep >= 4
            ? "cart_progress_step"
            : "cart_progress_step disabled"
        }
      >
        <span>
          <FontAwesomeIcon icon={faDollar} />
        </span>
        <span className="bigScreen">{translate("payment")}</span>
      </article>
    </div>
  );
}

export default ProgressBar;
