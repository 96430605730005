import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentStep: 1,
  maxStep: 1,
  cid: 0,
};

const cartProgressBarSlice = createSlice({
  name: "cartProgressBar",
  initialState,
  reducers: {
    incrementStep: (state) => {
      if (state.maxStep < state.currentStep + 1) {
        state.maxStep = state.currentStep + 1;
      }
      state.currentStep += 1;
    },
    goToStep: (state, action) => {
      if (action.payload <= state.maxStep) {
        state.currentStep = action.payload;
      }
    },
    setMaxStep: (state, action) => {
      state.currentStep = action.payload;
      state.maxStep = action.payload;
    },
    setCid: (state, action) => {
      state.cid = action.payload;
    },
  },
});

export default cartProgressBarSlice.reducer;
export const { incrementStep, goToStep, setMaxStep, setCid } = cartProgressBarSlice.actions;
