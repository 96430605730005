import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  emailComplete,
  nameComplete,
  phoneNumComplete,
} from "../../../features/cartFields";
import {
  updateEmail,
  updateName,
  updatePhoneNum,
} from "../../../features/dataSlice";
import { LOCALES, translate } from "../../../i18n";

function PersonalData() {
  const { name, phoneNum, email } = useSelector((state) => state.data.personal);

  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();

  // check everything if is already complete (retarded code)
  useEffect(() => {
    if (name.length > 1) {
      dispatch(nameComplete(true));
    } else {
      dispatch(nameComplete(false));
    }
    let tempPhone = "";
    if (phoneNum.length > 0) {
      if (phoneNum.length > 9) {
        tempPhone = phoneNum;
      } else {
        if (!/[0-9]/.test(phoneNum[phoneNum.length - 1])) {
          tempPhone = phoneNum.slice(0, -1);
        } else {
          tempPhone = phoneNum;
        }
      }
    } else {
      tempPhone = "";
    }

    if (tempPhone.length === 9) {
      dispatch(phoneNumComplete(true));
    } else {
      dispatch(phoneNumComplete(false));
    }
    // if (email.length > 5 && email.includes("@") && email.includes(".")) {
    //   dispatch(emailComplete(true));
    // } else {
    //   dispatch(emailComplete(false));
    // }
  }, []);

  const handleNameChange = (e) => {
    dispatch(updateName(e));
    if (e.length > 1) {
      dispatch(nameComplete(true));
    } else {
      dispatch(nameComplete(false));
    }
  };
  const handlePhoneNumberChange = (e) => {
    let tempPhone = "";
    if (e.length > 0) {
      if (e.length > 9) {
        tempPhone = phoneNum;
      } else {
        if (!/[0-9]/.test(e[e.length - 1])) {
          tempPhone = e.slice(0, -1);
        } else {
          tempPhone = e;
        }
      }
    } else {
      tempPhone = "";
    }

    dispatch(updatePhoneNum(tempPhone));

    if (tempPhone.length === 9) {
      dispatch(phoneNumComplete(true));
    } else {
      dispatch(phoneNumComplete(false));
    }
  };
  const handleEmailChange = (e) => {
    dispatch(updateEmail(e));
    // if (e.length > 5 && e.includes("@") && e.includes(".")) {
    //   dispatch(emailComplete(true));
    // } else {
    //   dispatch(emailComplete(false));
    // }
  };

  return (
    <div className="cart_personalData cart_main blackboard">
      <article>
        <label htmlFor="name" className="required">{translate("name")}:</label>
        <input
          className="required"
          tabIndex={1}
          value={name}
          placeholder={language === LOCALES.ROMANIAN ? "Numele, prenumele" : "Имя, фамилия"}
          type="text"
          name="name"
          id="name"
          onChange={(e) => {
            handleNameChange(e.target.value);
          }}
        />
      </article>
      <article>
        <label className="required" htmlFor="phoneNum">{translate("phone")}:</label>
        <input
          className="required"
          tabIndex={2}
          value={phoneNum}
          placeholder="079123456"
          type="tel"
          name="phone"
          id="phone"
          onChange={(e) => {
            handlePhoneNumberChange(e.target.value);
          }}
        />
      </article>
      {/* <article>
        <label htmlFor="email">{translate("email")}:</label>
        <input
          // className="required"
          tabIndex={3}
          value={email}
          placeholder="E-mail"
          alt="E-mail"
          type="text"
          name="email"
          id="email"
          onChange={(e) => {
            handleEmailChange(e.target.value);
          }}
        />
      </article> */}
    </div>
  );
}

export default PersonalData;
