import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementStep,
  setCid,
  setMaxStep,
} from "../../../features/cartProgressBarSlice.js";
import { useGetCartProducts } from "../../../helpers/customHooks/useGetCartProducts.js";
import { useGetCartProductsCompact } from "../../../helpers/customHooks/useGetCartProductsCompact.js";
import { LOCALES, translate } from "../../../i18n/index.js";
import CheckBox from "./CheckBox.js";
import axios from "axios";
import { BASE_URL } from "../../../constants";
import { cleanCart } from "../../../features/cartSlice.js";
import Promo from "./Promo.js";

function Summary() {
  const [checked, setChecked] = useState(false);
  const [promoCode, setPromoCode] = useState(localStorage.getItem("promocode"));
  const [discount, setDiscount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const orderConfirmation = useSelector(
    (state) => state.data.orderConfirmation
  );

  useEffect(() => {
    const asyncfunction = async () => {
      try {
        const codeExists = await axios.get(
          `${BASE_URL}/promocodes/${promoCode}`
        );
        // console.log(codeExists);
        if (codeExists.data.code) {
          setDiscount(codeExists.data.discount);
        } else {
          setDiscount(0);
          setPromoCode("");
          localStorage.setItem("promocode", "");
        }
      } catch (error) {
        // console.log(error);
        setDiscount(0);
        setPromoCode("");
        localStorage.setItem("promocode", "");
      }
    };
    asyncfunction();
  }, []);

  /////
  const cart = useSelector((state) => state.cart);
  const data = JSON.parse(localStorage.getItem("data"));
  ////
  const language = useSelector((state) => state.language);
  const currentStep = useSelector((state) => state.cartProgressBar.currentStep);
  const nameComplete = useSelector((state) => state.cartFields.name);
  const phoneNumComplete = useSelector((state) => state.cartFields.phoneNum);
  const emailComplete = true;
  const streetComplete = useSelector((state) => state.cartFields.street);
  const buildingComplete = useSelector((state) => state.cartFields.building);
  const cartProducts = useGetCartProducts();
  const cartProductsCompact = useGetCartProductsCompact();

  let cartHasFiller = false;
  console.log(cartProducts);
  cartProducts.forEach((item) => {
    if (item.type === "filler") {
      cartHasFiller = true;
      console.log(cartHasFiller);
    }
  });

  const subTotal = cartProducts.reduce(
    (accumulator, product) => accumulator + product.price * product.quantity,
    0
  );
  const totalPrice = subTotal - discount;
  // const totalPrice = Math.floor(subTotal - (subTotal * discount) / 100);

  const createErrorMessage = (message, step) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage("");
    }, 7000);
    dispatch(setMaxStep(step));
  };
  const nextStep = () => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
    setErrorMessage("");
    if (currentStep + 1 === 5) {
      placeOrderHandler();
    }
    dispatch(incrementStep());
  };

  const placeOrderHandler = async () => {
    // console.log(cartProducts);
    // console.log(cart);
    // console.log(cartProductsCompact);

    // console.log(orderConfirmation);
    const order = {
      cart: cartProductsCompact,
      name: data.personal.name,
      phone: data.personal.phoneNum,
      email: data.personal.email,
      street: data.address.street,
      building: data.address.building,
      entrance: data.address.entrance,
      floor: data.address.floor,
      apartment: data.address.apartment,
      comment: data.address.comment,
      payment: data.payment,
      subTotalPrice: subTotal,
      totalPrice: totalPrice,
      promo: { code: promoCode, discount: discount },
      orderConfirmation: orderConfirmation,
    };
    // console.log(order);
    const theCreatedOrder = await axios.post(`${BASE_URL}/orders`, order);
    const createdOrderCid = await theCreatedOrder.data.custom_id;

    await dispatch(setCid(createdOrderCid));

    // await console.log(theCreatedOrder);
    if (discount > 0) {
      await axios.patch(`${BASE_URL}/promocodes/${promoCode}`);
    }
    //
    setPromoCode("");
    setDiscount(0);
    localStorage.setItem("promocode", "");
    dispatch(cleanCart());
  };

  const handleButton = () => {
    let stepsComplete = 0;
    if (subTotal >= 300 || cartHasFiller) {
      currentStep >= 1 && (stepsComplete = 1);
      currentStep === 1 && stepsComplete === 1 && nextStep();
    } else {
      createErrorMessage(
        language === LOCALES.ROMANIAN
          ? `Coșul trebuie să conțină așternutul "KiWi" SAU hrană de 300 lei sau mai mult`
          : `Корзина должна содеражать наполнитель "KiWi" ЛИБО корм на 300 лей и более`,
        1
      );
    }

    if (currentStep >= 2 && stepsComplete >= 1) {
      nameComplete
        ? phoneNumComplete
          ? emailComplete
            ? currentStep >= 2 && (stepsComplete = 2) // if current step is 2 then we go to 3
            : createErrorMessage(
                language === LOCALES.ROMANIAN
                  ? 'Email-ul nu conține "@" sau "." sau este prea scurt'
                  : 'E-mail не содержит "@" или "." либо он слишком короткий',
                2
              )
          : createErrorMessage(
              language === LOCALES.ROMANIAN
                ? "Telefonul introdus nu corespunde formatului"
                : "Телефон не соответствует формату ",
              2
            )
        : createErrorMessage(
            language === LOCALES.ROMANIAN
              ? 'Сâmpul "Nume" nu este completat'
              : 'Поле "Имя" не заполнено',
            2
          );
      currentStep === 2 && stepsComplete === 2 && nextStep();
    }
    if (currentStep >= 3 && stepsComplete >= 2) {
      streetComplete
        ? buildingComplete
          ? currentStep >= 3 && (stepsComplete = 3) // if current step is 2 then we go to 3
          : createErrorMessage(
              language === LOCALES.ROMANIAN
                ? 'Сâmpul "Casa/blocul" nu este completat'
                : 'Поле "Дом/блок" не заполнено',
              3
            )
        : createErrorMessage(
            language === LOCALES.ROMANIAN
              ? 'Сâmpul "Strada" nu este completat'
              : 'Поле "Улица" не заполнено',
            3
          );
      currentStep === 3 && stepsComplete === 3 && nextStep();
    }
    if (currentStep >= 4 && stepsComplete >= 3) {
      if (checked) {
        nextStep();
      } else {
        createErrorMessage(
          language === LOCALES.ROMANIAN
            ? "Bifați marcajul mai sus pentru a continua"
            : "Отметьте галочку выше чтобы продолжить",
          4
        );
      }
    }
  };

  return (
    <div className="cart_right">
      <section className="cart_summary blackboard price ">
        <h6>{translate("total")}:</h6>
        <p>
          {translate("totalOfProducts")}:
          <span>
            {" "}
            {subTotal}{" "}
            <span className="price_currency">{translate("lei")}</span>
          </span>
        </p>
        {/* <p>
          {translate("shipping")}:
          <span>
            {" "}
            0 <span className="price_currency">{translate("lei")}</span>
          </span>
        </p> */}
        <Promo
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          discount={discount}
          setDiscount={setDiscount}
        />
        <div className="cart_summary_total">
          {translate("total")}:
          <span>
            {" "}
            {totalPrice}{" "}
            <span className="price_currency">{translate("lei")}</span>
          </span>
        </div>
        <button disabled type="button button--disabled" onClick={handleButton}>
          {translate("nextCart")}
        </button>
      </section>
      <div className="cart_belowSummary">
        <CheckBox checked={checked} setChecked={setChecked} />
        {errorMessage && (
          <article className="cart_error">{errorMessage}</article>
        )}
      </div>
    </div>
  );
}

export default Summary;

// {currentStep === 1 ? (
//   <button
//     type="button"
//     disabled={cartProducts.length < 1}
//     className={cartProducts.length < 1 && "button--disabled"} // maybe sa fac sa fie totul in handlebutton
//     onClick={handleButton}
//   >
//     Mai departe
//   </button>
// ) : currentStep === 2 ? (
//   <button
//     type="submit"
//     disabled={cartProducts.length < 1}
//     className={cartProducts.length < 1 && "button--disabled"} //check formele din personaldata,
//     form="personalDataForm" // if(ii corect) atunci dispatch ca spoate "mai departe"
//   >
//     Mai departe2
//   </button>
// ) : currentStep === 3 ? (
//   <button
//     type="submit"
//     disabled={cartProducts.length < 1}
//     className={cartProducts.length < 1 && "button--disabled"}
//     form="addressForm"
//   >
//     Mai departe3
//   </button>
// ) : currentStep === 4 ? (
//   <button
//     type="button"
//     disabled={cartProducts.length < 1}
//     className={cartProducts.length < 1 && "button--disabled"}
//     onClick={handleButton}
//   >
//     Mai departe4
//   </button>
// ) : (
//   ""
// )}
