import React, { useEffect, useState } from "react";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { translate } from "../../../i18n";
import axios from "axios";
import { BASE_URL } from "../../../constants";
import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import { setMaxStep } from ". ./../../features/cartProgressBar  Slice";
// import { useNavigate } from "react-router-dom";

function OrderPlaced() {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // let lastOrder_custom_id;
  // const [locid, setLocid] = useState("***");

  // useEffect(() => {
  //   const getLastOrderCID = async () => {
  //     let lastOrder_custom_idTEMP;
  //     try {
  //       lastOrder_custom_idTEMP = await axios.get(`${BASE_URL}/ordersgeneral`);
  //     } catch (error) {
  //       lastOrder_custom_idTEMP = "error 500";
  //       console.log(error.message);
  //     }
  //     await setLocid(lastOrder_custom_idTEMP.data.lastOrder_custom_id);
  //   };
  //   getLastOrderCID();
  // }, []);

  const cid = useSelector((state) => state.cartProgressBar.cid);

  return (
    <div className="cart_orderPlaced">
      <div className="f1">
        {translate("orderPlaced")}
        <span>
          <FontAwesomeIcon icon={faShoppingBasket} />
        </span>
        <br></br>
        <p>
          {translate("orderPlacedNR")} {cid === 0 ? "..." : cid}
        </p>
      </div>
      {/* <button
        onClick={() => {
          dispatch(setMaxStep(1));
          navigate("/");
          navigate(0);
        }}
      >
        {translate("finish")}
      </button> */}
    </div>
  );
}

export default OrderPlaced;
