import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRomanian, setRussian } from "../../../features/languageSlice";
import { LOCALES } from "../../../i18n";

function Language() {
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();

  const handleChangeLanguage = () => {
    if (language === LOCALES.ROMANIAN) {
      dispatch(setRussian());
    } else {
      dispatch(setRomanian());
    }
  };

  return (
    <article onClick={handleChangeLanguage} className="infobar_language link">
      <span>{language === LOCALES.ROMANIAN ? "RU" : "RO"}</span>
    </article>
  );
}

export default Language;
