import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateOrderConfirmation, updatePayment } from "../../../features/dataSlice";
import { translate } from "../../../i18n";

function Payment() {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.data.payment);
  const orderConfirmation = useSelector((state) => state.data.orderConfirmation);

  const handlePaymentChange = (e) => {
    // console.log(e.target.value);
    dispatch(updatePayment(e.target.value));
  };

  const handleOrderConfirmationChange = () => {
    console.log(!orderConfirmation);
    dispatch(updateOrderConfirmation(!orderConfirmation));
  };

  return (
    <div className="cart_main blackboard">
      <section className="cart_payment">
        <article>
          <input
            checked={payment === "cash"}
            type="radio"
            name="payment"
            id="cash"
            value="cash"
            onChange={(e) => handlePaymentChange(e)}
          />
          <label htmlFor="cash">{translate("cash")}</label>
        </article>
        <article>
          <input
            checked={payment === "card"}
            type="radio"
            name="payment"
            id="card"
            value="card"
            onChange={(e) => handlePaymentChange(e)}
          />
          <label htmlFor="card">{translate("card")}</label>
        </article>
      </section>
      {/* <section className="cart_orderConfirmation">
        <article>
          <input
            type="checkbox"
            name="orderConfirmation"
            id="orderConfirmation"
            value="orderConfirmation"
            checked={orderConfirmation}
            onChange={() => handleOrderConfirmationChange()}
          />
          <label htmlFor="orderConfirmation">{translate("orderConfirmation")}</label>
        </article>
      </section> */}
    </div>
  );
}

export default Payment;
