import React, { useEffect } from "react";
import ImageSlider from "./ImageSlider/ImageSlider";
import Products from "./HomeProducts/HomeProducts";
import { useDispatch, useSelector } from "react-redux";
import { setMaxStep } from "../../../features/cartProgressBarSlice";
import NavigationButtons from "./NavigationButtons";
import MainInformation from "./MainInformation";

function MainContentScreen() {
  const currentStep = useSelector((state) => state.cartProgressBar.currentStep);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentStep === 5) {
      dispatch(setMaxStep(1));
      window.location.reload(false);
    }
  }, []);

  return (
    <div className="mainContent">
      <ImageSlider />
      {/* <MainInformation/> */}
      {/* <NavigationButtons /> */}
      <Products />
    </div>
  );
}

export default MainContentScreen;
