import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setMaxStep } from "../../../features/cartProgressBarSlice";

function Cart() {
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.cartProgressBar.currentStep);
  const cartSize = useSelector((state) => state.cart).length;
  return (
    <article className="navbar_cart">
      <Link
        onClick={() => {
          if (currentStep !== 5) {
            dispatch(setMaxStep(1));
          }
        }}
        to="/cart"
      >
        <FontAwesomeIcon icon={faShoppingCart} className="navbar_cart_icon" />
        {cartSize > 0 && currentStep !== 5 && (
          <div className="navbar_cart_size">{cartSize}</div>
        )}
      </Link>
    </article>
  );
}

export default Cart;
