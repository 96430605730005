import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  name: false,
  phoneNum: false,
  street: false,
  building: false,
};
const cartFieldsSlice = createSlice({
  name: "cartFields",
  initialState,
  reducers: {
    nameComplete: (state, action) => {
      state.name = action.payload;
    },
    phoneNumComplete: (state, action) => {
      state.phoneNum = action.payload;
    },
    streetComplete: (state, action) => {
      state.street = action.payload;
    },
    buildingComplete: (state, action) => {
      state.building = action.payload;
    },
    
  },
});

export default cartFieldsSlice.reducer;
export const {
  nameComplete,
  phoneNumComplete,
  streetComplete,
  buildingComplete,
} = cartFieldsSlice.actions;
