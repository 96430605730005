import { useSelector } from "react-redux";
import products from "../../data";

export function useGetCartProducts() {
  const cart = useSelector((state) => state.cart);
  const cartProducts = cart.map((item) => {
    return {
      ...products.find((product) => item.id === product._id),
      quantity: item.quantity,
    };
  });
  return cartProducts;
}
