import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function ButtonHomeMobile() {
  return (
    <article className="navbar_button navbar_button--mobile">
      <Link to={"/"}>
        <div className="navbar_button_content">
          <FontAwesomeIcon className="navbar_faicon" icon={faHome} />
        </div>
      </Link>
      <span></span>
    </article>
  );
}

export default ButtonHomeMobile;
