import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("data")
  ? JSON.parse(localStorage.getItem("data"))
  : {
      personal: {
        name: "",
        phoneNum: "",
        email: "",
      },
      address: {
        street: "",
        building: "",
        entrance: "",
        floor: "",
        apartment: "",
        comment: "",
      },
      payment: "cash",
      orderConfirmation: false,
    };

const updateLocalStorage = (data) => {
  localStorage.setItem("data", JSON.stringify(data));
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    updateName: (state, action) => {
      state.personal = { ...state.personal, name: action.payload };
      updateLocalStorage(state);
    },
    updatePhoneNum: (state, action) => {
      state.personal = { ...state.personal, phoneNum: action.payload };
      updateLocalStorage(state);
    },
    updateEmail: (state, action) => {
      state.personal = { ...state.personal, email: action.payload };
      updateLocalStorage(state);
    },
    updateStreet: (state, action) => {
      state.address = { ...state.address, street: action.payload };
      updateLocalStorage(state);
    },
    updateBuilding: (state, action) => {
      state.address = { ...state.address, building: action.payload };
      updateLocalStorage(state);
    },
    updateEntrance: (state, action) => {
      state.address = { ...state.address, entrance: action.payload };
      updateLocalStorage(state);
    },
    updateFloor: (state, action) => {
      state.address = { ...state.address, floor: action.payload };
      updateLocalStorage(state);
    },
    updateApartment: (state, action) => {
      state.address = { ...state.address, apartment: action.payload };
      updateLocalStorage(state);
    },
    updateComment: (state, action) => {
      state.address = { ...state.address, comment: action.payload };
      updateLocalStorage(state);
    },
    updatePayment: (state, action) => {
      state.payment = action.payload;
      updateLocalStorage(state);
    },
    updateOrderConfirmation: (state, action) => {
      state.orderConfirmation = action.payload;
      updateLocalStorage(state);
    },
  },
});

export default dataSlice.reducer;
export const {
  updateName,
  updatePhoneNum,
  updateEmail,
  updateStreet,
  updateBuilding,
  updateEntrance,
  updateFloor,
  updateApartment,
  updateComment,
  updatePayment,
  updateOrderConfirmation
} = dataSlice.actions;
