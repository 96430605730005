import React from "react";
import Carousel from "react-material-ui-carousel";
import Slide from "./Slide";
import { LOCALES } from "../../../../i18n";
import { useSelector } from "react-redux";

function ImageSlider() {
  const language = useSelector((state) => state.language);

  let foodLink = "";
  if (language === LOCALES.RUSSIAN) {
    foodLink = "./images/slider/foodru.jpg";
  } else {
    foodLink = "./images/slider/foodro.jpg";
  }

  const slides = [
    // { id: 1, image: foodLink },
    { id: 1, image: "./images/slider/1.jpg" },
    { id: 2, image: "./images/slider/2.jpg" },
    { id: 3, image: "./images/slider/3.jpg" },
    { id: 4, image: "./images/slider/4.jpg" },
    { id: 5, image: "./images/slider/5.jpg" },
  ];

  return (
    <section className="imageSlider">
      <div className="container">
        <Carousel>
          {slides.map((slide) => (
            <Slide key={slide.id} image={slide.image} />
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default ImageSlider;
