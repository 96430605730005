import React from "react";
import { translate } from "../../../i18n";
import Button from "./Button";
import ButtonCatsMobile from "./ButtonCatsMobile";
import ButtonDogsMobile from "./ButtonDogsMobile";
import ButtonHomeMobile from "./ButtonHomeMobile";
import ButtonInfoMobile from "./ButtonInfoMobile";
import DropDownButton from "./DropDownButton";

function Buttons() {
  return (
    <section className="navbar_buttons">
      <Button name={translate("homePage")} type="" link="/" />
      {/* <DropDownButton name={translate("cats")} type="cat" link="/listings/cats" />
      <DropDownButton name={translate("dogs")} type="dog" link="/listings/dogs" /> */}
      <Button
        name={translate("deliveryAndPayment")}
        type=""
        link="/delivery-and-payment"
      />
      <ButtonHomeMobile />
      <ButtonInfoMobile />
      {/* <ButtonCatsMobile />
      <ButtonDogsMobile /> */}
      {/* <Button name="Pesici" /> */}
      {/* <Button name="Cățăi" /> */}
    </section>
  );
}

export default Buttons;
