import { LOCALES } from "../locales";

const translationKeys = {
  [LOCALES.RUSSIAN]: {
    ynformation:
      "Бесплатная для любого заказа, если в нём присутствует наполнитель KiWi.",
    homePage: "Главная страница",
    deliveryAndPayment: "Доставка и оплата",
    cats: "Кошки",
    dogs: "Собаки",
    productsTitleHome: "Наши товары",
    productPageButtonHome: "страница товара",
    lei: "лей",
    theProduct: "Продукт",
    wasAddedToCart: "был добавлен в корзину",
    addToCartButton: "Добавить в корзину",
    emptyCart: "Корзина пуста",
    nextCart: "Далее",
    sum: "Сумма",
    total: "Итого",
    totalOfProducts: "Продуктов на",
    shipping: "Доставка",
    checkboxText:
      "Отправляя заказ я принимаю условия политики конфиденциальности",
    name: "Имя",
    phone: "Номер",
    email: "Почта",
    street: "Улица",
    building: "Дом/блок",
    entrance: "Подъезд",
    floor: "Этаж",
    apartment: "Квартира",
    comment: "Комментарий",
    cash: "Оплата наличными",
    card: "Оплата карточкой при доставке",
    cart: "Корзина",
    personalData: "Личные данные",
    address: "Адрес",
    payment: "Оплата",
    continueShopping: "Продолжить покупки",
    goToCart: "Перейти в корзину",
    orderPlaced: "Заказ был получен",
    orderPlacedNR: "Номер заказа:",
    finish: "Закончить",
    contact: "Контакты",
    delivery: "Доставка",
    delivery1: "Мин. цена: 150 лей (+ доставка 40 лей)",
    delivery2: "Бесплатная доставка для любого заказа",
    sellingOnly: "Продаётся только в наборе из",
    unit: "шт.",
    promocode: "Промо-код",
    discount: "Скидка",
    apply: "Применить",
    cancel: "Отменить",
    invalid: "Такого промо-кода не существует",
    infodeliverytitle1: "Условия доставки",
    infodeliverytitle2: "Доставка по Кишинёву:",
    infodeliveryfloor: "Доставка производится до дверей вашего дома, в случае если заказ нужно доставить выше 3-го этажа (в домах без лифта), то нужно будет доплатить курьеру 30 лей за поднятие груза на необходимый этаж.",
    infodelivery1:
      "бесплатная для любого заказа, если в нём присутствует наполнитель KiWi.",
    infodelivery2: "осуществляется с понедельника по субботу с 10:00 до 19:00",
    infodeliveryNew3:
      "если в заказе отсутствует наполнитель KiWi, тогда минимальный заказ от 300 лей, и доставка также бесплатная.",
    infodelivery3:
      "заказы принятые до 10:00 текущего дня, доставляются в тот же день. Если заказ был осуществлён позже 10:00 тогда он доставляется либо в этот же день, либо на следующий день.",
    infodelivery4:
      "Важно понимать, что мы не всегда можем доставить заказ в указанное вами время, так как делаются множество заказов в одно и то же время в разных уголках города. Поэтому курьер предварительно свяжется с Вами и согласует  взаимоприемлемое время доставки.",
    infodeliverytitle3: "Доставка по пригороду Кишинёва, а также в район Аэропорта и Яловены:",
    infodelivery5:
      "только при заказе более 500 лей, и также является абсолютно бесплатной.",
    infodelivery6:
      "Доставка в другие районы обговаривается отдельно, и только при большом заказе.",
    infopaymenttitle1: "Оплата",
    infopayment1:
      "Оплата производится наличными или банковской картой курьеру при получении заказа.",
    infoproductstitle1:
      "Просим обратить внимание что наполнитель продается только наборами:",
    infoproducts1: "из пяти пакетов - для маленьких (2 кг, 3 кг)",
    infoproducts2: "из двух пакетов - для больших (7 кг)",
    orderConfirmation: "Перезвонить для подтверждения",
    catProducts: "Товары для кошек",
    dogProducts: "Товары для собак",
    all: "Все",
    theProducts: "товары",
    food: "Еда",
    dry: "Сухой",
    wet: "Влажный",
    filler: "Наполнитель",
    accessory: "Аксессуары",
    hygienic: "гигиенический",
    sort1: "Сортировать (По умолчанию)",
    sort2: "Цена по возрастанию",
    sort3: "Цена по убыванию",
    comingSoon: "В скором времени появится",
    announcement1:
      "🔔 ВНИМАНИЕ! На данный момент мы не осуществляем доставку!!!",
    announcement2:
      "Наши товары можете заказать в торговой сети ZooExpert, либо в других специализированных магазинах",
  },
};

export default translationKeys;
