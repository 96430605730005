import React, { useEffect, useState } from "react";
import { useGetCartProducts } from "../../../../helpers/customHooks/useGetCartProducts";
import { translate } from "../../../../i18n";
import Product from "./CartProduct";

function Products() {
  const [reload, setReload] = useState(false);
  const cartProducts = useGetCartProducts();

  useEffect(() => {
    setReload(!reload);
  }, []);

  return (
    <ul className="cart_products cart_main blackboard">
      {cartProducts.length ? (
        cartProducts.map((product) => {
          return <Product key={product._id} product={product} />;
        })
      ) : (
        <div className="cart_products_empty">{translate("emptyCart")}</div>
      )}
    </ul>
  );
}

export default Products;
