import React from "react";
import Cart from "./Cart";
import Searchbar from "../InfobarComponents/Searchbar";

function Utilities() {
  return (
    <section className="navbar_utilities">
      {/* <Searchbar /> */}
      <Cart />
    </section>
  );
}

export default Utilities;
