import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hideInfoBar: false,
  lastScrollY: 0,
};

const scrollSlice = createSlice({
  name: "scroll",
  initialState,
  reducers: {
    changeHeader: (state, action) => {
      if (state.lastScrollY < window.scrollY) {
        if (window.scrollY > 55) {
          state.hideInfoBar = true;
        }
      } else {
        state.hideInfoBar = false;
      }

      state.lastScrollY = action.payload;
    },
  },
});

export default scrollSlice.reducer;
export const { changeHeader } = scrollSlice.actions;
