import React from "react";
import { Link } from "react-router-dom";

function Logo() {
  return (
    <article className="infobar_logo">
      <Link to="/">
        <span>KiWi</span>
      </Link>
    </article>
  );
}

export default Logo;
