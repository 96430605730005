import React, { useState } from "react";
import axios from "axios";
import "./Login.scss";
import { BASE_URL } from "../../../constants";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}/login`, {
        username,
        password,
      });

      const { token } = response.data;

      localStorage.setItem("token", token);

      //   let history = useHistory();
      // history.push('/');
      //   console.log(`Received JWT: ${token}`);
    } catch (error) {
      //   console.error("Failed to log in", error);
    }
  };

  return (
    <div className="login-form">
      <div className="container">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" value="Submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
