import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import Searchbar from "./Searchbar";

function Info() {
  // const telephonenr = "079379610";
  // const telephonenr = "079079461";
  // const telephonenr = "078101064";
  const telephonenr = "076607777";

  return (
    <div className="infobar_mid">
      {/* <article className="infobar_info">
        <FontAwesomeIcon className="infobar_faicon" icon={faCircleInfo} />{" "}
        <span>{translate("ynformation")}</span>
      </article> */}
      <Searchbar/>
      {/*  */}
      <article className="infobar_info link">
        <a href={`tel:${telephonenr}`}>
          <FontAwesomeIcon className="infobar_faicon" icon={faPhone} />{" "}
          <span>{telephonenr}</span>
        </a>
      </article>
    </div>
  );
}

export default Info;
