import React from "react";

function Slide(props) {
  const { image } = props;
  return (
    <article className="imageSlider_slide">
      <img src={image} alt="Zoomagazin" />
    </article>
  );
}

export default Slide;
