import React from "react";
import { translate } from "../../../i18n";

function Banner() {
  return (
    <div className="announcement-banner">
      <span className="square">{translate("announcement1")}</span> {" "}
      <span className="blues">{translate("announcement2")}</span>
    </div>
  );
}

export default Banner;
