import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];

const updateLocalStorage = (cart) => {
  localStorage.setItem("cart", JSON.stringify(cart));
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const idsMatch = state.find((item) => item.id === action.payload.id);
      if (idsMatch !== (null || undefined)) {
        state.find((item) => item.id === action.payload.id).quantity +=
          action.payload.quantity;
      } else {
        state.push({
          id: action.payload.id,
          quantity: action.payload.quantity,
        });
      }
      updateLocalStorage(state);
    },
    removeFromCart: (state, action) => {
      // redux toolkit filter array
      state.forEach((item, index) => {
        if (item.id === action.payload) {
          state.splice(index, 1);
        }
      });
      updateLocalStorage(state);
    },
    updateQuantity: (state, action) => {
      state.forEach((item) => {
        if (item.id === action.payload.id) {
          item.quantity = action.payload.quantity;
        }
      });
      updateLocalStorage(state);
    },
    cleanCart: (state) => {
      state = [];
      updateLocalStorage(state);
    },
  },
});

export default cartSlice.reducer;
export const { addToCart, removeFromCart, updateQuantity, cleanCart } =
  cartSlice.actions;
