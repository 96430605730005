import React, { useEffect, useState } from "react";
import "./ProductModal.scss";
import ProductQuantity from "../ProductQuantity/ProductQuantity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../features/cartSlice.js";
import { LOCALES, translate } from "../../../i18n";
import { useSelector } from "react-redux";
import { goToStep } from "../../../features/cartProgressBarSlice";
import { productsRussian } from "../../../data";

function ProductModal(props) {
  const { modal, setModal, product } = props;
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [savedQuantity, setSavedQuantity] = useState(1);
  const [productAddedToCart, setProductAddedToCart] = useState(false);

  const currentStep = useSelector((state) => state.cartProgressBar.currentStep);

  const modalClosed = () => {
    setModal(false);
    setProductAddedToCart(false);
    setQuantity(1);
    setSavedQuantity(1);
  };

  const [productLocal, setProductLocal] = useState(product);
  const language = useSelector((state) => state.language);
  useEffect(() => {
    const productRu = productsRussian.find((prod) => prod._id === product._id);

    if (language === LOCALES.RUSSIAN) {
      setProductLocal({
        ...product,
        name: productRu.name,
        description: productRu.description,
      });
    } else {
      setProductLocal(product);
    }
  }, [language]);
  return (
    <>
      {modal && (
        <div className="productModal">
          <div
            onClick={() => modalClosed()}
            className="productModal_overlay"
          ></div>
          <div className="productModal_content">
            <section
              className={
                productAddedToCart
                  ? "productModal_content_main added"
                  : "productModal_content_main"
              }
            >
              <div className="productModal_image">
                <img src={product.image} alt={productLocal.name} />
              </div>
              <div className="productModal_info">
                <button
                  onClick={() => modalClosed()}
                  className="productModal_close"
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
                <div className="productModal_flex first">
                  <h2 className="productModal_name">{productLocal.name}</h2>
                  <p className="productModal_price">
                    {product.price}{" "}
                    <span className="price_currency">{translate("lei")}</span>{" "}<span className="article">(
                    {product.priceUnit}{" "}
                    <span className="price_currency">{translate("lei")}</span> x{" "}
                    {product.complect} {translate("unit")})</span>
                  </p>
                </div>
                <div className="productModal_flex">
                  <ProductQuantity
                    quantity={quantity}
                    setQuantity={setQuantity}
                  />
                  <div className="productModal_addToCartButton">
                    <button
                      onClick={() => {
                        setProductAddedToCart(!productAddedToCart);
                        setSavedQuantity(quantity);
                        dispatch(
                          addToCart({ id: product._id, quantity: quantity })
                        );
                      }}
                      type="button"
                      className={
                        productAddedToCart || currentStep === 5
                          ? "button--disabled"
                          : ""
                      }
                      disabled={productAddedToCart || currentStep === 5}
                    >
                      <span>{translate("addToCartButton")}</span>
                      <FontAwesomeIcon icon={faCartPlus} />
                    </button>
                  </div>
                </div>
              </div>
            </section>
            {productAddedToCart && (
              <section className="productModal_success">
                <p>
                  {translate("theProduct")} «{productLocal.name}» x{" "}
                  {savedQuantity} {translate("wasAddedToCart")}
                </p>
                <div className="productModal_success_buttons">
                  <div>
                    <button
                      onClick={() => modalClosed()}
                      className="productModal_success_button"
                      type="button"
                    >
                      {translate("continueShopping")}
                    </button>
                  </div>

                  <Link
                    onClick={() => {
                      if (currentStep !== 5) {
                        dispatch(goToStep(1));
                      }
                    }}
                    to="/cart"
                  >
                    <button
                      className="productModal_success_button"
                      type="button"
                    >
                      {translate("goToCart")}
                    </button>
                  </Link>
                </div>
              </section>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ProductModal;
