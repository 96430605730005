import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { removeFromCart, updateQuantity } from "../../../../features/cartSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { LOCALES, translate } from "../../../../i18n";
import { productsRussian } from "../../../../data";

function Product(props) {
  const { product } = props;
  const dispatch = useDispatch();
  const quantity = useSelector(
    (state) => state.cart.find((item) => item.id === product._id).quantity
  );
  function quantityChange(quantity) {
    quantity < 1
      ? dispatch(removeFromCart(product._id))
      : quantity > 999
      ? dispatch(updateQuantity({ id: product._id, quantity: 999 }))
      : dispatch(updateQuantity({ id: product._id, quantity: quantity }));
  }

  const [productLocal, setProductLocal] = useState(product);
  const language = useSelector((state) => state.language);
  useEffect(() => {
    const productRu = productsRussian.find((prod) => prod._id === product._id);

    if (language === LOCALES.RUSSIAN) {
      setProductLocal({
        ...product,
        name: productRu.name,
        description: productRu.description,
      });
    } else {
      setProductLocal(product);
    }
  }, [language]);
  return (
    <li className="cart_product">
      <button onClick={() => quantityChange(0)} className="cart_product_remove">
        <FontAwesomeIcon icon={faXmark} />
      </button>
      <article className="cart_product_image">
        <Link to={`/product/${product._id}`}>
          <img src={product.image} alt="kiwi classic pellets" />
        </Link>
      </article>
      <div className="cart_product_layering">
        <article className="cart_product_name">
          <Link to={`/product/${product._id}`}>
            <h2>
              {productLocal.name.length > 50
                ? productLocal.name.substring(0, 50) + "..."
                : productLocal.name}
            </h2>
          </Link>
        </article>
        <article className="cart_product_price price">
          <p>
            {product.price}{" "}
            <span className="price_currency">{translate("lei")}</span>
            <span className="cart_product_price_calc">
              <span> x {quantity} = </span>
              {product.price * quantity}{" "}
              <span className="price_currency">{translate("lei")}</span>
            </span>
          </p>
        </article>
      </div>
      <article className="cart_product_quantity">
        <div className="cart_product_quantity_display">{quantity}</div>
        <div className="cart_product_quantity_buttons">
          <button
            type="button"
            className="cart_product_quantity_buttons_add"
            onClick={() => quantityChange(quantity + 1)}
          >
            +
          </button>
          <button
            type="button"
            className="cart_product_quantity_buttons_subtract"
            onClick={() => quantityChange(quantity - 1)}
          >
            -
          </button>
        </div>
      </article>
      <article className="cart_product_totalPrice price">
        <span>{translate("sum")}:</span>
        <p>
          {product.price * quantity}{" "}
          <span className="price_currency">{translate("lei")}</span>
        </p>
      </article>
    </li>
  );
}

export default Product;
