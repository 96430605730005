import React, { useState } from "react";
import { useSelector } from "react-redux";
import { LOCALES, translate } from "../../../i18n";
import axios from "axios";
import { BASE_URL } from "../../../constants";

function Promo(props) {
  const [notification, setNotification] = useState(false);
  const { promoCode, setPromoCode, discount, setDiscount } = props;
  const language = useSelector((state) => state.language);
  const handlePromoCodeChange = (value) => {
    if (discount === 0) {
      setPromoCode(value);
    }
  };
  const handleApply = async () => {
    try {
      const codeExists = await axios.get(`${BASE_URL}/promocodes/${promoCode}`);
      // console.log(codeExists);
      if (codeExists.data.code) {
        setNotification(false);
        setDiscount(codeExists.data.discount);
        localStorage.setItem("promocode", promoCode);
      } else {
        if (promoCode !== "") {
          setDiscount(0);
          setNotification(true);
          localStorage.setItem("promocode", promoCode);
          setTimeout(() => {
            setNotification(false);
          }, 4000);
        }
      }
    } catch (error) {
      setDiscount(0);
      setNotification(true);
      setTimeout(() => {
        setNotification(false);
      }, 4000);
    }
  };
  const handleCancel = () => {
    setDiscount(0);
    localStorage.setItem("promocode", "");
  };
  return (
    <div className="promo">
      {notification && (
        <article className="promo_notification">{translate("invalid")}</article>
      )}
      <div>
        {translate("discount")}:{" "}
        <span className={discount !== 0 ? "discounted" : "notDiscounted"}>
          {discount} {translate("lei")}
        </span>
      </div>
      <section className="promo_field">
        <input
          disabled={discount !== 0}
          value={promoCode}
          type="text"
          placeholder={
            language === LOCALES.ROMANIAN ? "Cod promoțional" : "Промо-код"
          }
          onChange={(e) => handlePromoCodeChange(e.target.value)}
        />
        {discount === 0 ? (
          <button className="promo_apply" onClick={handleApply}>
            {translate("apply")}
          </button>
        ) : (
          <button className="promo_cancel" onClick={handleCancel}>
            {translate("cancel")}
          </button>
        )}
      </section>
    </div>
  );
}

export default Promo;
