import React from "react";
import { translate } from "../../../i18n";

function CheckBox(props) {
  const { checked, setChecked } = props;
  return (
    <div className="cart_checkbox">
      <input
        type="checkbox"
        name="check"
        id="check"
        checked={checked}
        onChange={() => {
          setChecked(!checked);
        }}
      />
      <label htmlFor="check">
        {" "}
        {translate("checkboxText")}
        
      </label>
    </div>
  );
}

export default CheckBox;
