const products = [
  {
    _id: "1",
    name: "KiWi Mix (set din 5 pachete diferite)",
    description: `Așternut igienic din lemn pentru animalele de companie în setul nostru mix KiWi, care conține cinci tipuri diferite: KiWi Clasic 3kg, KiWi Brad 3kg, KiWi Junior 3kg, KiWi Lavanda 3kg și KiWi Porumb 2kg.`,
    priceUnit: 44,
    complect: 5,
    price: 220,
    nonSalePrice: 48.6,
    image: "/images/kiwi/mix.jpg",
    image2: "/images/kiwi/mix.jpg",
    type: "hidden",
    animal: "cat",
  },
  {
    _id: "2",
    name: "KiWi Lavanda, 3kg (set din 5 buc.)",
    description: `Așternut igienic pentru animale de companie, în formă de peleți 6 mm. Produs din rumeguș de lemn cu miros fin de levănțică. 
    Ambalat într-un pachet de hârtie. Masa netă 3 kg.`,
    priceUnit: 48,
    complect: 5,
    price: 240,
    nonSalePrice: 0,
    image: "/images/kiwi/lavanda.jpg",
    image2: "/images/kiwi/zlavanda.jpg",
    type: "filler",
    animal: "cat",
  },
  {
    _id: "3",
    name: "KiWi Clasic, 3kg (set din 5 buc.)",
    description: `Așternut igienic pentru animale de companie, în formă de peleți 6 mm. Produs din rumeguș de lemn de specii alese. 100% natural, fără adaosuri.
    Ambalat într-un pachet de hârtie. Masa netă 3 kg.`,
    priceUnit: 44,
    complect: 5,
    price: 220,
    nonSalePrice: 48,
    image: "/images/kiwi/clasic.jpg",
    image2: "/images/kiwi/zclasic.jpg",
    type: "filler",
    animal: "cat",
  },
  {
    _id: "4",
    name: "KiWi Brad, 3kg (set din 5 buc.)",
    description: `Așternut igienic pentru animale de companie, în formă de peleți 6mm. Produs exclusiv din lemn de brad, are un miros plăcut de brad natural. 
    Ambalat într-un pachet de hârtie. Masa netă 3 kg.`,
    priceUnit: 50,
    complect: 5,
    price: 250,
    nonSalePrice: 0,
    image: "/images/kiwi/brad.jpg",
    image2: "/images/kiwi/zbrad.jpg",
    type: "hidden",
    animal: "cat",
  },
  {
    _id: "5",
    name: "KiWi Junior, 3kg (set din 5 buc.)",
    description: `Așternut igienic pentru juniori, în formă de peleți 6mm cu lungime mai mică. Produs din rumeguș de lemn de specii alese. 
    Ambalat într-un pachet de hârtie. Masa netă 3 kg`,
    priceUnit: 50,
    complect: 5,
    price: 250,
    nonSalePrice: 0,
    image: "/images/kiwi/junior.jpg",
    image2: "/images/kiwi/zjunior.jpg",
    type: "hidden",
    animal: "cat",
  },
  {
    _id: "6",
    name: "KiWi Porumb, 2kg (set din 5 buc.)",
    description: `Așternut igienic pentru animalele de companie în form de peleți 2.5 mm. Produs din porumb prelucrat. Se aglomerează și se elimină în sistemul de canalizare. 
    Ambalat într-un pachet de hârtie. Masa netă 2 kg.`,
    priceUnit: 45,
    complect: 5,
    price: 225,
    nonSalePrice: 0,
    image: "/images/kiwi/porumb.jpg",
    image2: "/images/kiwi/zporumb.jpg",
    type: "filler",
    animal: "cat",
  },
  {
    _id: "7",
    name: "KiWi Clasic, 7kg (set din 2 buc.)",
    description: `6 kg + 1 kg cadou (7 kg la preț de 6 kg) 
    Așternut clasic ambalat în pachet mare de hârtie cu masa netă 7 kg.`,
    priceUnit: 100,
    complect: 2,
    price: 200,
    nonSalePrice: 0,
    image: "/images/kiwi/7kg_hirtie.jpg",
    image2: "/images/kiwi/zclasic7.jpg",
    type: "hidden",
    animal: "cat",
  },
  {
    _id: "8",
    name: "KiWi Clasic, 7kg (set din 2 buc.)",
    description: `6 kg + 1 kg cadou (7 kg la preț de 6 kg) 
    Așternut clasic ambalat în pachet de polietilenă cu masa netă 7 kg.`,
    priceUnit: 98,
    complect: 2,
    price: 196,
    nonSalePrice: 0,
    image: "/images/kiwi/7kg.jpg",
    image2: "/images/kiwi/zclasic7.jpg",
    type: "filler",
    animal: "cat",
  },
  {
    _id: "9",
    name: "KiWi Clasic, 15kg  Polietilen",
    description: `Așternut clasic ambalat în pachet de polietilenă cu masa netă 15 kg.`,
    priceUnit: 210,
    complect: 1,
    price: 210,
    nonSalePrice: 0,
    image: "/images/kiwi/15kg_clasic.jpg",
    image2: "/images/kiwi/15kg_clasic.jpg",
    type: "filler",
    animal: "cat",
  },
  {
    _id: "10",
    name: "KiWi Lavanda, 3kg (1 buc; de proba)",
    description: `Pachet 3kg lavanda de proba`,
    priceUnit: 50,
    complect: 1,
    price: 50,
    nonSalePrice: 0,
    image: "/images/kiwi/clasic.jpg",
    image2: "/images/kiwi/zclasic.jpg",
    type: "hidden",
    animal: "cat",
  },
  {
    _id: "11",
    name: "KiWi Clasic, 3kg (1 buc; de proba)",
    description: `Pachet 3kg clasic de proba`,
    priceUnit: 44,
    complect: 1,
    price: 44,
    nonSalePrice: 48,
    image: "/images/kiwi/clasic.jpg",
    image2: "/images/kiwi/zclasic.jpg",
    type: "hidden",
    animal: "cat",
  },
  {
    _id: "12",
    name: "KiWi Brad, 3kg (1 buc; de proba)",
    description: `Pachet 3kg brad de proba`,
    priceUnit: 50,
    complect: 1,
    price: 50,
    nonSalePrice: 0,
    image: "/images/kiwi/clasic.jpg",
    image2: "/images/kiwi/zclasic.jpg",
    type: "hidden",
    animal: "cat",
  },
  {
    _id: "13",
    name: "KiWi Junior, 3kg (1 buc; de proba)",
    description: `Pachet 3kg junior de proba`,
    priceUnit: 50,
    complect: 1,
    price: 50,
    nonSalePrice: 0,
    image: "/images/kiwi/clasic.jpg",
    image2: "/images/kiwi/zclasic.jpg",
    type: "hidden",
    animal: "cat",
  },
  {
    _id: "14",
    name: "KiWi Porumb, 2kg (1 buc; de proba)",
    description: `Pachet 2kg porumb de proba`,
    priceUnit: 45,
    complect: 1,
    price: 45,
    nonSalePrice: 0,
    image: "/images/kiwi/clasic.jpg",
    image2: "/images/kiwi/zclasic.jpg",
    type: "hidden",
    animal: "cat",
  },
];

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////

export const productsRussian = [
  {
    _id: "1",
    name: "KiWi Микс (набор из 5-ти разных пакетов)",
    description: `"Древесный гигиенический наполнитель для домашних животных в нашем наборе KiWi, который включает в себя пять различных видов: KiWi Clasic 3 кг, KiWi Brad 3 кг, KiWi Junior 3 кг, KiWi Lavanda 3 кг и KiWi Porumb 2 кг.`,
  },
  {
    _id: "2",
    name: "KiWi Lavanda, 3kg (набор из 5 шт.)",
    description: `Гигиенический Наполнитель для домашних питомцев в виде древесных пеллет 6 мм. Древесные пеллеты с приятным запахом лаванды. 
    Упакован в бумажный пакет, масса нетто 3 кг.`,
  },
  {
    _id: "3",
    name: "KiWi Clasic, 3кг (набор из 5 шт.)",
    description: `Гигиенический Наполнитель для домашних питомцев в виде древесных пеллет 6 мм. Произведён из отборных сортов дерева. 100% натуральный. 
    Упакован в бумажный пакет, масса нетто 3 кг.`,
  },
  {
    _id: "4",
    name: "KiWi Brad, 3кг (набор из 5 шт.)",
    description: `Гигиенический Наполнитель для домашних питомцев в виде древесных пеллет 6 мм. Произведён исключительно из еловых сортов дерева, имеет приятный аромат натуральной ели.
    Упакован в бумажный пакет, масса нетто 3 кг.`,
  },
  {
    _id: "5",
    name: "KiWi Junior, 3кг (набор из 5 шт.)",
    description: `Гигиенический Наполнитель для юнных домашних питомцев в виде древесных пеллет 6 мм с уменьшенной длиной. Произведён из отборных сортов дерева.
    Упакован в бумажный пакет, масса нетто 3 кг.`,
  },
  {
    _id: "6",
    name: "KiWi Porumb, 2кг (набор из 5 шт.)",
    description: `Гигиенический Наполнитель для домашних питомцев в виде пеллет 2.5 мм. Произведён исключительно из переработанной кукурузы. Комкуется и утилизируется прямо в канализацию. 
    Упакован в бумажный пакет, масса нетто 2 кг.`,
  },
  {
    _id: "7",
    name: "KiWi Clasic, 7кг (набор из 2 шт.)",
    description: `6 кг + 1 кг  (7 кг по цене 6 кг)
    Классический наполнитель упакованный в большой бумажный пакет с чистой массой 7 кг.`,
  },
  {
    _id: "8",
    name: "KiWi Clasic, 7кг, Полиэтилен (набор из 2 шт.)",
    description: `6 кг + 1 кг  (7 кг по цене 6 кг)
    Классический наполнитель упакованный в полиэтиленовый пакет с чистой массой 7 кг.`,
  },
  {
    _id: "9",
    name: "KiWi Clasic, 20 кг  Полиэтилен",
    description: `Классический наполнитель упакованный в полиэтиленовый пакет с чистой массой 20 кг.`,
  },
];

export default products;
