import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { translate } from '../../../i18n'

function ButtonInfoMobile() {
  return (
    <article className="navbar_button navbar_button--mobile">
      <Link to={"/delivery-and-payment"}>
        <div className="navbar_button_content"><p>{translate("deliveryAndPayment")} </p>
        <FontAwesomeIcon className="navbar_faicon" icon={faInfoCircle} /></div>
      </Link>
      <span></span>
    </article>
  )
}

export default ButtonInfoMobile