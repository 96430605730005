import React from "react";
import { translate } from "../../../i18n";

function DeliveryAndPayment() {
  return (
    <section className="deliveryAndPayment">
      <div className="container">
        <h2>{translate("infodeliverytitle1")}</h2>
        <article>
          <h3>{translate("infodeliverytitle2")}</h3>
          <h4>
            <span>+</span> {translate("infodelivery1")}
          </h4>
          <h4>
            <span>+</span> {translate("infodelivery2")}
          </h4>
          <h4>
            <span>+</span> {translate("infodeliveryNew3")}
          </h4>
          <h4>
            <span>*</span> {translate("infodeliveryfloor")}
          </h4>
        </article>
        <article>
          <h3>{translate("infodeliverytitle3")}</h3>
          <h4>
            <span>+</span> {translate("infodelivery5")}
          </h4>
        </article>
        <article>
          <h3>{translate("infopaymenttitle1")}</h3>
          <h4>{translate("infopayment1")}</h4>
        </article>
        <article>
          <h4>
            <span>*</span> {translate("infodelivery3")}
          </h4>
          <h4>
            <span>**</span> {translate("infodelivery4")}
          </h4>
          <h4>
            <span>***</span> {translate("infoproductstitle1")}
          </h4>
          <h4>
            <span>+</span> {translate("infoproducts1")}
          </h4>
          <h4>
            <span>+</span> {translate("infoproducts2")}
          </h4>
        </article>
      </div>
    </section>
  );
}

export default DeliveryAndPayment;
