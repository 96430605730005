import React from "react";
import Info from "./InfobarComponents/Info";
import Language from "./InfobarComponents/Language";
import Logo from "./InfobarComponents/Logo";

function InfoBar(props) {
  const { infoBarHidden } = props;

  return (
    <div className={infoBarHidden ? "infobar infobar--hidden" : "infobar"}>
      <div className="container">
        <section className="infobar_content">
          <Logo />
          <Info />
          <Language />
        </section>
      </div>
    </div>
  );
}

export default InfoBar;
