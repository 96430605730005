import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";

const PrivateRoute = ({ roles, children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      console.log("1");

      // Check if token is expired
      if (decodedToken.exp < Date.now() / 1000) {
        console.log("2");
        localStorage.removeItem("token");
        navigate("/");
      }

      // Check if user has right role
      else if (!roles.includes(decodedToken.role)) {
        console.log("3");
        navigate("/");
      }
      setIsLoading(false);
    } else {
      console.log("4");
      navigate("/");
    }
  }, [navigate, roles, token]);
  console.log("5");
  // Render the children (AdminPanel) only when loading is false
  return !isLoading && children;
};

export default PrivateRoute;
