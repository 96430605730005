import { useGetCartProducts } from "./useGetCartProducts";

export function useGetCartProductsCompact() {
  const cartProductsTemp = useGetCartProducts();
  const cartProducts = cartProductsTemp.map((item) => {
    return {
      id: item._id,
      name: item.name,
      price: item.price,
      quantity: item.quantity,
    };
  });

  return cartProducts;
}
