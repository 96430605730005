import React from "react";
import { Link } from "react-router-dom";

function Button(props) {
  const { name, caret, type, link } = props;
  return (
    <article className="navbar_button">
      <Link to={link}>
        <div className="navbar_button_content">{name}</div>
      </Link>
      <span></span>
    </article>
  );
}

export default Button;
