import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import products from "../../../data";
import { LOCALES, translate } from "../../../i18n";

function Searchbar() {
  const language = useSelector((state) => state.language);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => {setFocused(true);}
  const onBlur = () => {setFocused(false);}

  const searchInput = React.useRef(null);

  function handleSearch(e) {
    const tempValue = e.toUpperCase();

    const tempArray = products.filter((product) =>
      product.name.toUpperCase().includes(tempValue)
    );

    if (e !== "" && e !== " ") {
      setSearchResults(tempArray);
    } else {
      setSearchResults([]);
    }
    setSearchValue(e);
  }

  function handleRedirect() {
    setSearchResults([]);
    setSearchValue("");
  }

  return (
    <article  className={
      focused
        ? "infobar_search infobar_search--focused"
        : "infobar_search"
    }>
      <input
        ref={searchInput}
        onFocus={onFocus}
        onBlur={onBlur}
        type="text"
        placeholder={
          language === LOCALES.ROMANIAN
            ? "Caută produs..."
            : "Поиск продукта..."
        }
        className={
          focused
            ? "infobar_search_searchbar--focused"
            : "infobar_search_searchbar"
        }
        value={searchValue}
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
      />
      <button
        onClick={() => {
          searchInput.current.focus();
        }}
        type="submit"
        className="infobar_search_button"
      >
        <FontAwesomeIcon icon={faSearch} />
      </button>

      <ul
        className={
          focused
            ? "infobar_search_results"
            : "infobar_search_results infobar_search_results--off"
        }
      >
        {searchResults.map((product) => {
          if (product.type === "hidden") {
            return null;
          }
          return (
            <Link
              key={product._id}
              onClick={handleRedirect}
              to={`/product/${product._id}`}
            >
              <li className="infobar_search_result">
                <img src={product.image} alt="::" />
                <div>
                  <h4>{product.name}</h4>
                  <h6>
                    {product.priceUnit}{" "}
                    <span className="">{translate("lei")} </span> x{" "}
                    {product.complect}
                  </h6>
                </div>
              </li>
            </Link>
          );
        })}
      </ul>
    </article>
  );
}

export default Searchbar;
