import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../constants";
import "./IonDulap.scss";

// Adjust date formatting to consider time zone
const formatDate = (dateString) => {
  return dateString;
};

function IonDulap() {
  const [orders, setOrders] = useState([]);
  const [moreOrders, setMoreOrders] = useState(false);

  useEffect(() => {
    const fetchLastOrders = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/orders/lastOrders`);
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchLastOrders();
  }, []);

  const fetchMoreOrders = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/orders/lastOrdersMore`);
      setOrders(response.data);
      setMoreOrders(true);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  return (
    <div className="iondulap">
      <h2>Ultimele 15 comenzi</h2>
      {Object.keys(orders).map((date) => (
        <div key={date}>
          <h3>{formatDate(date)}</h3>
          {orders[date].map((order) => (
            <article key={order.custom_id}>
              {/* {order.custom_id + "; "} */}
              {/* {order.date + "; "} */}
              {order.cart.map((item) => (
                <p className="iondulap_product" key={item._id}>
                  {item.quantity > 1
                    ? `${item.name} x ${item.quantity}`
                    : item.name}{" "}
                </p>
              ))}
              {"; "}
              {"total " + order.totalPrice + " lei; "}
              <span className="iondulap_phone">{order.phone + "; "}</span>
              <p>
                adresa:{" "}
                <span className="iondulap_adresa">{`${order.street} ${order.building}`}</span>
                {order.entrance && `; sc: ${order.entrance}`}
                {order.floor && `; et: ${order.floor}`}
                {order.apartment && `; ap: ${order.apartment}`}
              </p>
              <p>comentariu: {order.comment ? order.comment : "-"}</p>
              <p class="iondulap_plata">
                plata: <span>{order.payment}</span>
              </p>
            </article>
          ))}
        </div>
      ))}
      {!moreOrders && (
        <button class="iondulap_button" onClick={fetchMoreOrders}>
          Apasa ca sa vezi mai multe comenzi (ultimile 50)
        </button>
      )}
    </div>
  );
}

export default IonDulap;
