import { createSlice } from "@reduxjs/toolkit";
import { LOCALES } from "../i18n";

const initialState = localStorage.getItem("language")
  ? JSON.parse(localStorage.getItem("language"))
  : LOCALES.ROMANIAN;

const updateLocalStorage = (language) => {
  localStorage.setItem("language", JSON.stringify(language));
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setRussian: (state) => {
      updateLocalStorage(LOCALES.RUSSIAN);
      return LOCALES.RUSSIAN;
    },
    setRomanian: (state) => {
      updateLocalStorage(LOCALES.ROMANIAN);
      return LOCALES.ROMANIAN;
    },
  },
});

export default languageSlice.reducer;
export const { setRussian, setRomanian } = languageSlice.actions;
